.navbar{
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
}
.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-logo p{
    color: #171717;
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
}

.nav-menu{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: #626262;
    font-size: 20px;
    font-weight: 500;
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}
.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 45px;
}
.nav-login-cart button{
    width: 157px;
    height:58px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
}
.nav-login-cart button:active{
    background-color: #f3f3f3;
}
.nav-cart-count{
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}

/* Responsive styles */
@media (max-width: 900px) {
    .nav-menu {
        gap: 20px; /* Reduce gap for smaller screens */
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .nav-logo p {
        font-size: 20px; /* Smaller logo text size */
    }

    .nav-login-cart {
        gap: 30px; /* Reduce gap for smaller screens */
    }

    .nav-login-cart button {
        font-size: 18px; /* Smaller button font size */
        width: 130px; /* Reduce button width for smaller screens */
    }
}

@media (max-width: 600px) {
    .nav-menu {
        flex-direction: column; /* Stack menu items vertically */
        gap: 10px; /* Reduce gap for very small screens */
    }

    .nav-cart-count {
        margin-top: 0; /* Reset margin for cart count */
        margin-left: 10px; /* Adjust position for smaller screens */
    }

    .navbar {
        justify-content: center; /* Center content for smaller screens */
        flex-wrap: wrap; /* Allow items to wrap */
    }
}