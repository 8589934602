.productdisplay {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin: 0 170px;
    flex-wrap: wrap;
}

.productdisplay-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.productdisplay-img-list {
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 8px;
    object-fit: contain;
}

/* Make sure all images are the same size */
.productdisplay-left img {
    height: 100px; /* Set a fixed height for thumbnails */
    width: 100px; /* Ensure thumbnails take full width */
    object-fit: contain; /* Maintain aspect ratio */
    cursor: pointer; /* Pointer cursor for thumbnails */
}


.productdisplay-main-img {
    max-height: 350px;  /* Set a maximum height */
    width: auto;        /* Adjust width to maintain aspect ratio */
    object-fit: contain; /* Preserve the aspect ratio within the available space */
    margin: auto;        /* Center the image horizontally */
}

.productdisplay-right {
    flex: 1;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right h1, .productdisplay-right-size-h h1 {
    font-size: 22px;
    font-weight: 700;
    color: #3d3d3d;
}

.productdisplay-right-prices {
    display: flex;
    align-items: baseline;
    gap: 1px;
    font-size: 24px;
    font-weight: 700;
    margin: 1px 0;
}

.productdisplay-right-price-new {
    margin-left: 20px;
    color: black;
}

.productdisplay-right-size-h {
    margin-top: 20px;
}

.productdisplay-right-size-dropdown {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.productdisplay-right-category {
    margin-top: 10px;
}

.productdisplay-right-category span {
    font-weight: 600;
}

.productdisplay-right button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #ff4141;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 30px;
}

.productdisplay-right button:hover {
    background-color: #ff6767;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .productdisplay {
        flex-direction: column; /* Stack items vertically */
        margin: 0 40px; /* Adjust margins for smaller screens */
    }

    .productdisplay-left,
    .productdisplay-right {
        margin: 0; /* Reset margins */
    }

    .productdisplay-main-img {
        width: 100%; /* Full width for main image */
        height: 350px; /* Keep the height consistent */
        object-fit: contain; /* Maintain aspect ratio */
    }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .productdisplay {
        flex-direction: column; /* Stack the items vertically */
        align-items: center; /* Center align items */
    }

    .productdisplay-left {
        flex-direction: row; /* Make the image list horizontal */
        justify-content: center; /* Center the image list */
        margin-top: 20px; /* Add some space between the main image and the image list */
    }

    .productdisplay-img-list {
        flex-direction: row; /* Change direction to horizontal */
        gap: 10px; /* Adjust the gap for horizontal layout */
    }

    .productdisplay-right {
        margin: 20px 0 0 0; /* Remove left margin and add top margin */
    }

    .productdisplay-main-img {
        width: 100%; /* Make the main image take full width */
        max-height: 300px; /* Adjust the max height for smaller screens */
        object-fit: contain; /* Keep the aspect ratio */
    }
}


/* @media (max-width: 480px) {
    .productdisplay {
        margin: 0 20px; 
    }

    .productdisplay-right-prices {
        flex-direction: column; 
        gap: 10px; 
    }

    .productdisplay-right-size-h {
        margin-top: 10px; 
    }
} */
