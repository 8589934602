.breadcrum{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 600;
    margin: 60px 170px;
    text-transform: capitalize;
}

.breadcrum-link {
    text-decoration: none; /* Remove underline */
    color: #000; /* Change color to your desired text color */
    font-weight: bold; /* Make the text bold */
}

.breadcrum-link:hover {
    text-decoration: underline; /* Optional: add underline on hover */
}