.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-logo p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;    
}
.footer-links{
    display: flex;
    list-style: none;
    gap: 50px;
    color: #252525;
    font-size: 20px;
}
.footer-links li{
    cursor: pointer;
}
.footer-social-icon{
    display: flex;
    gap: 20px;
}
.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}