.item {
    width: 350px;
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    align-items: center; /* Center the content horizontally */
}

.item img {
    width: 100%; /* Full width for images */
    height: 200px; /* Set a fixed height to standardize image size */
    object-fit: contain; /* Ensure the image maintains aspect ratio and covers the area */
    border-radius: 6px;
}

.item p {
    margin: 6px 0;
    font-size: 16px;
    color: #333;
    text-align: center; /* Center the text for uniformity */
}

.item-prices {
    display: flex;
    gap: 20px;
    align-items: baseline;
    justify-content: center; /* Center prices horizontally */
}

.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive styles */
@media (max-width: 600px) {
    .item p {
        font-size: 14px; /* Smaller text size for mobile */
    }

    .item-price-new,
    .item-price-old {
        font-size: 16px; /* Adjust price size for mobile */
    }

    .item {
        padding: 8px; /* Less padding on mobile */
    }
}