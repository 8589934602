.cartitems {
  margin: 80px auto;
  max-width: 1200px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  padding: 0 20px;
}

.cartitems hr {
  height: 1px;
  background: #dcdcdc;
  border: none;
}

.cartitems-format-main {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* More concise grid definition */
  /* grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr; */
  align-items: center;
  gap: 20px;
  padding: 15px 0;
  color: #555;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
}

.cartitems-format {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* More concise grid definition */

  /* grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr; */
  align-items: center;
  gap: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.carticon-product-icon {
  width: 50px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cartitems-remove-icon {
  width: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.cartitems-remove-icon:hover {
  transform: scale(1.2);
}

.cartitems-quantity {
  display: flex; /* Use flex for alignment */
  align-items: center; /* Center align items */
}

.cartitems-add-icon {
  width: 20px; /* Adjust size as needed */
  cursor: pointer;
  margin-right: 5px; /* Space between the icon and the quantity */
  transition: transform 0.2s ease;
}

.cartitems-add-icon:hover {
  transform: scale(1.1); /* Slightly increase size on hover for effect */
}

.cartitems-quantity button {
  width: 50px;
  height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  background-color: #f9f9f9; /* Light background for better visibility */
  transition: border-color 0.2s ease;
}

.cartitems-quantity button:focus {
  outline: none;
  border-color: #ff4d4d; /* Change border color on focus */
}

.cartitems-down {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
}

.cartitems-total {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
}

.cartitems-total-item {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 10px 0;
  color: #666;
}

.cartitems-total h1 {
  font-size: 28px;
  font-weight: bold;
  color: #444;
}

.cartitems-total h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

.cartitems-total p {
  font-size: 18px;
}

.cartitems-total button {
  width: 100%;
  padding: 15px;
  background-color: #ff4d4d;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cartitems-total button:hover {
  background-color: #e03c3c;
}

.cartitems-promo {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.cartitems-promo input {
  padding: 12px;
  border: 1px solid #ccc;
  width: 75%;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
}

.cartitems-promo button {
  padding: 12px 20px;
  background-color: #333;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cartitems-promo button:hover {
  background-color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cartitems-format-main, .cartitems-format {
    grid-template-columns: repeat(3, 1fr); /* Adjust columns for smaller screens */
  }
  
  .cartitems-down {
    flex-direction: column; /* Stack total items vertically */
    align-items: flex-start; /* Align items to the start */
  }
  
  .cartitems-total {
    width: 100%; /* Make total section full width */
  }

  .cartitems-total-item {
    flex-direction: column; /* Stack the total items vertically */
    align-items: flex-start; /* Align items to the left */
    padding: 5px 0; /* Reduce padding */
  }

  .cartitems-total h1 {
    font-size: 24px; /* Adjust header size */
  }

  .cartitems-total h3 {
    font-size: 20px; /* Adjust subheader size */
  }
}
