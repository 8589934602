.kollektion {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
}

.kollektion h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10px;
}

.kollektion hr {
    width: 250px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
    margin-top: 10px;
}

/* Corrected the class name from .kollections to .kollektions */
.kollektions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 50px;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .kollektions {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
    }
}

@media (max-width: 900px) {
    .kollektions {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
    }
}

@media (max-width: 600px) {
    .kollektions {
        grid-template-columns: 1fr; /* 1 column for extra small screens */
    }

    .kollektion h1 {
        font-size: 36px; /* Smaller title font size for small screens */
    }

    .kollektion hr {
        width: 100%; /* Make the horizontal line full width on small screens */
    }
}
