.continue-container {
    padding: 20px;
    max-width: 1200px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container */
}

.form-cart-container {
    display: flex;
    flex-direction: row; /* */
    justify-content: space-between;
    gap: 20px; /* Space between form and cart */
    flex-wrap: wrap;
}

.form {
    flex: 1; /* Allow the form to take up available space */
    max-width: 500px; /* Set a maximum width for the form */
    min-width: 300px;
}

.form-input {
    margin: 10px 0;
    padding: 10px;
    width: 100%; /* Keep inputs full width of the form */
}

.submit-button {
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    color: white;
    background: #e03c3c;
    border-radius: 5px;
    border: none;
}

.kurv {
    width: 100%; /* Adjust width as needed */
    max-width: 400px;
    background: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
}

.kurv h2 {
    margin-top: 0;
}

.cart-item {
    margin-bottom: 20px;
}

.error {
    color: red;
}

.cart-item {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between text and image */
    align-items: center; /* Align items vertically center */
}

.cart-item-info {
    display: flex; /* Use flexbox for the info container */
    justify-content: space-between; /* Space out text and image */
    align-items: center; /* Center items vertically */
    flex: 1; /* Allow the text section to grow */
}

.cart-item-image {
    width: 100px; /* Set a fixed width */
    height: auto; /* Maintain aspect ratio */
    margin-left: 10px; /* Space between text and image */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .form-cart-container {
        flex-direction: column; /* Stack form and cart vertically */
        align-items: center; /* Center align items */
    }
    
    .kurv {
        width: 100%; /* Make cart section full width */
        max-width: none; /* Remove maximum width for smaller screens */
    }
    
    .cart-item {
        flex-direction: column; /* Stack item info vertically on small screens */
        align-items: flex-start; /* Align items to the start */
    }

    .cart-item-image {
        margin-left: 0; /* Remove margin for image */
        margin-top: 10px; /* Add margin for spacing */
    }
}